// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import AdBanner from './Adbanner';
import './App.css';

function App() {
    const [userId, setUserId] = useState("user123");
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [status, setStatus] = useState(''); 
    const [queuePosition, setQueuePosition] = useState(null);
    const [queueLength, setQueueLength] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [activeStream, setActiveStream] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [loadingDots, setLoadingDots] = useState('');

    useEffect(() => {
      let savedUserId = localStorage.getItem('user_id');
      if (!savedUserId) {
          savedUserId = crypto.randomUUID();  // Generates a unique ID
          localStorage.setItem('user_id', savedUserId);
      }
      setUserId(savedUserId);
  }, []);
    // Custom headers
    const headers = { 'X-Requested-With': 'gptinprivate-client' }; // basic identifier

    const cancelRequest = async () => {
      try {
        // Store current response before any state changes
        const finalResponse = response;
        
        // Abort the stream first
        if (activeStream) {
            activeStream.abort();
            setActiveStream(null);
        }

        // Send the leave queue request
        await axios.post('https://api.gptinprivate.com/api/leave_queue', { user_id: userId }, { headers });

        // Add to chat history if there's a response
        if (finalResponse && finalResponse.trim()) {
            setChatHistory(prev => [...prev, { 
                role: 'assistant', 
                content: finalResponse 
            }]);
        }

        // Clear states after ensuring the response is saved
        setResponse('');
        setQueuePosition(null);
        setQueueLength(null);
        setStatus('');
        setIsProcessing(false);
      } catch (error) {
        console.error("Error cancelling request", error);
      }
    };

    const handleSendMessage = async () => {
      if (!message.trim()) return; // Don't send empty messages
      
      if (queuePosition !== null) {
        await cancelRequest();
      }
      
      try {
        // Add user message to chat history immediately
        const userMessage = { role: 'user', content: message };
        setChatHistory(prev => [...prev, userMessage]);
        
        setIsProcessing(true);
        const res = await axios.post('https://api.gptinprivate.com/api/join_queue', 
          { user_id: userId }, 
          { headers }
        );
        
        if (res.status === 409) {
          setStatus('Another session is already active in a different tab');
          setIsProcessing(false);
          return;
        }
        
        setQueuePosition(res.data.queue_position);
        setQueueLength(res.data.queue_length);
        checkQueuePosition();
      } catch (error) {
        if (error.response?.status === 409) {
          setStatus('Another session is already active in a different tab');
        } else {
          console.error("Error joining queue", error);
          setStatus("Error joining the queue. Please try again later.");
        }
        setIsProcessing(false);
      }
    };

    const checkQueuePosition = async () => {
        try {
            const res = await axios.post('https://api.gptinprivate.com/api/check_queue', { user_id: userId }, { headers });
            setQueuePosition(res.data.queue_position);
            setQueueLength(res.data.queue_length);

            if (res.data.ready) {
                processPrompt();
            } else {
                setTimeout(checkQueuePosition, 3000);
            }
        } catch (error) {
            console.error("Error checking queue position", error);
            setTimeout(checkQueuePosition, 3000);
        }
    };

    const resetChat = () => {
      setChatHistory([]);
      setMessage('');
      setResponse('');
      setStatus('');
      setQueuePosition(null);
      setQueueLength(null);
      setIsProcessing(false);
      if (activeStream) {
        activeStream.abort();
        setActiveStream(null);
      }
    };

    const processPrompt = async () => {
        setMessage('');
        setResponse('');

        let accumulatedResponse = '';
        const controller = new AbortController();
        setActiveStream(controller);

        try {
            const response = await fetch('https://api.gptinprivate.com/api/generate_response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'gptinprivate-client'
                },
                body: JSON.stringify({ 
                    user_id: userId, 
                    prompt: message,
                    chat_history: chatHistory
                }),
                signal: controller.signal
            });
      
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
      
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';

            while (true) {
                const { done, value } = await reader.read();
                
                // Check if cancelled before processing new chunks
                if (controller.signal.aborted) {
                    if (accumulatedResponse) {
                        setResponse(accumulatedResponse);
                    }
                    break;
                }

                if (done) break;
          
                buffer += decoder.decode(value, { stream: true });
                const parts = buffer.split('\n\n');
                
                for (let i = 0; i < parts.length - 1; i++) {
                    const part = parts[i];
                    if (!part.trim()) continue;
                    
                    if (part.startsWith('data: ')) {
                        try {
                            const jsonStr = part.replace('data: ', '').trim();
                            const parsedChunk = JSON.parse(jsonStr);
                            
                            if (parsedChunk.response) {
                                accumulatedResponse += parsedChunk.response;
                                setResponse(accumulatedResponse);
                            }
                        } catch (err) {
                            console.error('Parse error:', err);
                        }
                    }
                }
                
                buffer = parts[parts.length - 1];
            }

            // Only add to history if not cancelled
            if (!controller.signal.aborted && accumulatedResponse) {
                setChatHistory(prev => [...prev, { 
                    role: 'assistant', 
                    content: accumulatedResponse 
                }]);
            }
            
            // Clear states
            setResponse('');
            setStatus('');
            setIsProcessing(false);
            setActiveStream(null);
            setQueuePosition(null);
            setQueueLength(null);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was cancelled');
                // Don't clear the response here
                return;
            }
            console.error('Streaming error:', error);
            setStatus('Error with streaming response. Please try again.');
            setQueuePosition(null);
            setQueueLength(null);
            setIsProcessing(false);
            setActiveStream(null);
        }
    };

    // Add cleanup effect
    useEffect(() => {
        return () => {
            if (activeStream) {
                activeStream.abort();
            }
        };
    }, [activeStream]);

    // Add this handler for Enter key
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          if (isProcessing) {
            e.preventDefault();
            cancelRequest(); // Call cancelRequest if processing
          } else {
            e.preventDefault();
            handleSendMessage();
          }
        }
      };

    // Add this useEffect for scroll behavior
    useEffect(() => {
      const chatContainer = document.getElementById('chat-messages');
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }, [chatHistory, response, status, isProcessing, queuePosition, queueLength]); // Scroll when these change

    // Update useEffect for dot animation (2-3 dots only)
    useEffect(() => {
        let dotsCount = 2;
        let intervalId;

        if (isProcessing && !response) {
            intervalId = setInterval(() => {
                dotsCount = dotsCount === 2 ? 3 : 2;
                setLoadingDots('.'.repeat(dotsCount));
            }, 500);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isProcessing, response]);

    return (
        <div className="App min-h-screen bg-neutral-100">
            <Navbar />
            
            <div className="max-w-7xl mx-auto px-4 py-8 h-[calc(100vh-64px)]">
                <div className="flex justify-center gap-8 h-full">
                    {/* Left ad space */}
                    <div className="w-[160px] hidden lg:block">
                        <AdBanner position="left" />
                    </div>

                    {/* Chat interface wrapper */}
                    <div className='relative flex-1 max-w-[800px] min-w-[320px]'>
                        {/* Main chat interface */}
                        <div className='w-full h-[80vh] bg-white shadow-lg rounded-[40px] p-4 sm:p-8 flex flex-col gap-4 sm:gap-6 min-w-[300px]'>
                            <div className='relative'>
                                <header className='w-full flex justify-between items-center'>
                                    <h1 className='text-2xl sm:text-3xl font-title text-neutral-950 font-bold'>GPT in Private</h1>
                                    <button 
                                        onClick={resetChat}
                                        className='h-[40px] w-[40px] sm:h-[48px] sm:w-[48px] flex items-center justify-center bg-neutral-200 hover:bg-neutral-300 rounded-full'
                                    >
                                        <span className="material-symbols-outlined" style={{ fontSize: '24px' }}>refresh</span>
                                    </button>
                                </header>
                            </div>

                            {/* Chat messages - adjust padding for mobile */}
                            <div 
                                id="chat-messages"
                                className='flex-1 overflow-y-auto bg-neutral-100 p-3 sm:p-4 rounded-[20px] scroll-smooth'
                            >
                                <div className='flex flex-col gap-4'>
                                    {/* Chat history */}
                                    {chatHistory.map((msg, index) => (
                                        <div 
                                            key={index} 
                                            className={`p-4 rounded-[20px] w-fit ${  // Changed to w-fit
                                                msg.role === 'user' 
                                                    ? 'bg-primary-500 text-primary-50 self-end max-w-[60%]' 
                                                    : 'bg-primary-950 text-primary-50 self-start max-w-[60%]'
                                            }`}
                                        >
                                            <p className="whitespace-pre-wrap break-words">{msg.content}</p> {/* Added whitespace and break handling */}
                                        </div>
                                    ))}

                                    {/* Status message */}
                                    {status && (
                                        <div className='bg-neutral-200 p-4 rounded-[20px] self-center w-fit'> {/* Changed to w-fit */}
                                            <p className="whitespace-pre-wrap break-words">{status}</p>
                                        </div>
                                    )}

                                    {/* Loading dots or current response */}
                                    {isProcessing && (response || (queuePosition !== null && queueLength !== null)) && (
                                        <div className='bg-primary-950 text-primary-50 p-4 rounded-[20px] self-start w-fit max-w-[60%]'>
                                            <p className="whitespace-pre-wrap break-words">
                                                {response || (
                                                    <span>
                                                        {`Queue Position: ${queuePosition}/${queueLength}${loadingDots}`}
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Input section - adjust for mobile */}
                            <div className='flex items-center gap-2 sm:gap-4'>
                                <div className='flex-1'>
                                    <input
                                        type='text'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onKeyDown={handleKeyPress}
                                        placeholder='Type your message...'
                                        className='w-full h-[40px] sm:h-[48px] px-3 sm:px-4 bg-neutral-200 text-neutral-950 rounded-[40px] outline-none'
                                    />
                                </div>
                                {isProcessing ? (
                                    <button 
                                        onClick={cancelRequest}
                                        className='h-[48px] w-[48px] flex items-center justify-center bg-red-500 hover:bg-red-600 text-white rounded-full'
                                    >
                                        <span className="material-symbols-outlined" style={{ fontSize: '24px' }}>close</span>
                                    </button>
                                ) : (
                                    <button 
                                        onClick={handleSendMessage}
                                        className='h-[48px] w-[48px] flex items-center justify-center bg-primary-500 hover:bg-primary-600 text-primary-50 rounded-full'
                                    >
                                        <span className="material-symbols-outlined" style={{ fontSize: '24px' }}>send</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Right ad space */}
                    <div className="w-[160px] hidden lg:block">
                        <AdBanner position="right" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
