import React from 'react';

function Navbar() {
    return (
        <nav className="flex justify-between items-center px-6 py-4 bg-primary-950 text-primary-50">
            <div className="flex items-center gap-2">
                <img src="/logo512.png" alt="GPT in Private Logo" className="w-14 h-14" />
                <div className="text-xl font-bold">GPT in Private</div>
            </div>
            <div className="flex gap-6">
                <a href="#home" className="hover:text-primary-200 transition-colors">Home</a>
                <a href="#about" className="hover:text-primary-200 transition-colors">About</a>
                <a href="#contact" className="hover:text-primary-200 transition-colors">Contact</a>
                <a href="#premium" className="hover:text-primary-200 transition-colors">Premium</a>
            </div>
        </nav>
    );
}

export default Navbar;
